import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './MainButtonsContainer.css';
import Select from 'react-select';
import { Badge, Profile } from '../hooks/useTalosBadges';
import { formatDistanceToNow } from 'date-fns';
import { CollectedBadgesContainer } from './CollectedBadgesContainer.tsx';
import { JourneysContainer } from './JourneysContainer.tsx';
import { exampleJourneys, useIsJourneyCompleted } from '../components/JourneyComponent.tsx';

const badge_submission_url = 'https://forms.gle/Dd9m5FEWtSZhn2fY7';
const edit_badges_url = 'https://forms.gle/ZSYwXq1teDooMrnK7';
const awardEmojis = ['🥇', '🥈', '🥉'] as const;
const talosBadgesLogoURL = `${process.env.PUBLIC_URL}/images/talos-badges-logo.png`;
// const ONE_DAY = 24 * 60 * 60 * 1000;

const useSyncedRef = <T,>(value: T) => {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};

interface MainButtonsContainerProps {
  profilesMap: Map<string, Profile>;
  badgesMap: Map<string, Badge>;
  selectedProfileID: string | null;
  setSelectedProfileID: (profileID: string | null) => void;
  selectedBadge: Badge | null;
  setSelectedBadge: (badge: Badge | null) => void;
  viewBadgeGallery: boolean;
  setViewBadgeGallery: (viewBadgeGallery: boolean) => void;
  setGhostProfileID: (profileID: string | null) => void;
  setImagesLoaded: (fullyLoaded: boolean) => void;
  mode: 'collected' | 'submitted' | 'journeys';
  setMode: (mode: 'collected' | 'submitted' | 'journeys') => void;
}

export const MainButtonsContainer = ({
  profilesMap,
  badgesMap,
  selectedProfileID,
  setSelectedProfileID,
  setSelectedBadge,
  viewBadgeGallery,
  setViewBadgeGallery,
  setGhostProfileID,
  setImagesLoaded,
  mode,
  setMode,
}: MainButtonsContainerProps) => {
  const [buttonPressed, setButtonPressed] = useState(false);
  const buttonPressedRef = useSyncedRef(buttonPressed);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState('score');

  const options = useMemo(() => {
    const options = Array.from(profilesMap.values()).map((profile, index) => ({
      value: profile.email,
      label: `${index <= 2 ? awardEmojis[index] : `#${index + 1}`} ${profile.name}`,
      score: profile.sortScore,
      lastUpdated: profile.lastUpdated,
    }));
    if (sortBy === 'score') {
      return options.sort((a, b) => b.score - a.score);
    } else if (sortBy === 'last updated') {
      return options.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
    }
    return [];
  }, [profilesMap, sortBy]);

  const handleKeyDown = useCallback(
    (e) => {
      if (menuIsOpen) {
        return;
      }
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
      }
      if ((e.key === 'ArrowUp' || e.key === 'ArrowLeft') && buttonPressedRef.current === false) {
        setButtonPressed(true);
        if (selectedProfileID == null) {
          return setSelectedProfileID(options[options.length - 1].value);
        }
        setSelectedProfileID(
          options[options.findIndex((option) => option.value === selectedProfileID) - 1]?.value ?? null
        );
      }
      if ((e.key === 'ArrowDown' || e.key === 'ArrowRight') && buttonPressedRef.current === false) {
        setButtonPressed(true);
        if (selectedProfileID == null) {
          return setSelectedProfileID(options[0].value);
        }
        setSelectedProfileID(
          options[options.findIndex((option) => option.value === selectedProfileID) + 1]?.value ?? null
        );
      }
    },
    [buttonPressedRef, options, selectedProfileID, setSelectedProfileID, menuIsOpen]
  );
  const handleKeyUp = useCallback(
    (e) => {
      if (buttonPressedRef.current === true) {
        setButtonPressed(false);
      }
    },
    [buttonPressedRef]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const selectedProfile = selectedProfileID ? profilesMap.get(selectedProfileID) ?? null : null;
  const selectedProfileName = selectedProfile?.email.split('@')[0].split('.')[0];

  const badgeSubmissions = useMemo(() => {
    if (selectedProfile == null) return [];
    return [...badgesMap.values()].filter((badge) => badge.credits.includes(selectedProfile.email));
  }, [badgesMap, selectedProfile]);

  useEffect(() => {
    if (mode === 'submitted' && badgeSubmissions.length === 0) {
      setMode('collected');
    }
  }, [badgeSubmissions.length, mode, selectedProfileID, setMode]);

  const sortedBadgesArrayForProfile: Badge[] = useMemo(() => {
    if (selectedProfile == null) return [];
    return selectedProfile.badgesIDs
      .map((badgeID) => badgesMap.get(badgeID) ?? null)
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        if (a.sortScore === b.sortScore) {
          return b.rarityScore - a.rarityScore;
        }
        return a.sortScore - b.sortScore;
      })
      .filter((badge) => badge != null) as Badge[];
  }, [selectedProfile, badgesMap]);

  const isConsideredMobile = window.innerWidth < 615;

  const BadgesSubmittedComponent = selectedProfile ? (
    <p>
      Badges Submitted{' '}
      <i style={{ letterSpacing: '0.3rem' }}>
        (
        <i style={{ letterSpacing: '0.2rem' }}>
          {badgeSubmissions.filter((badge) => !badge.credits.includes(`Design: ${selectedProfile.email}`)).length}
        </i>
        <i title="Badge idea">✏️</i>
        <i style={{ padding: '0 0.2rem' }}>+</i>
        <i style={{ letterSpacing: '0.2rem' }}>
          {badgeSubmissions.filter((badge) => badge.credits.includes(`Design: ${selectedProfile.email}`)).length}
        </i>
        <i title="Design contribution">🎨</i>)
      </i>
    </p>
  ) : null;

  const isDefault = !selectedProfileID && !viewBadgeGallery;

  const journeysIndexesCompeted = useMemo(() => {
    return (
      exampleJourneys
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .map((journey) => useIsJourneyCompleted(journey, selectedProfile?.badgesIDs ?? []))
    );
  }, [selectedProfile]);

  const numJourneysCompleted: number = useMemo(
    () => journeysIndexesCompeted.filter((isCompleted) => isCompleted === true).length,
    [journeysIndexesCompeted]
  );

  const getTitleByEmail = useCallback(
    (email: string) => {
      const profile = profilesMap.get(email);
      if (!profile) return '';
      const commonBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'common'
      ).length;
      const rareBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'rare'
      ).length;
      const legendaryBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'legendary'
      ).length;
      const totalBadgeScore = rareBadgesCount * 2 + commonBadgesCount + legendaryBadgesCount * 4;
      const totalScoreFromJourneys = profile?.sortScore - totalBadgeScore;
      return `+ ${legendaryBadgesCount * 4} pts from Legendary Badges
+ ${rareBadgesCount * 2} pts from Rare Badges
+ ${commonBadgesCount * 1} pts from Common Badges
+ ${totalScoreFromJourneys} pts from Journeys`;
    },
    [badgesMap, profilesMap]
  );

  const getLastUpdatedText = useCallback((profile: Profile) => {
    const updatedRecently = new Date().getTime() - profile.lastUpdated.getTime()!;
    const AN_HOUR = 60 * 60 * 1000;
    if (updatedRecently < AN_HOUR) {
      return 'less than an hour ago';
    }
    return formatDistanceToNow(profile.lastUpdated, { addSuffix: true });
  }, []);

  const handleFormatOptionLabel = useCallback(
    (data: { value: string; label: string }) => {
      const profile = profilesMap.get(data.value);
      if (!profile) return null;
      const isUpdatedRecently = true;

      return (
        <div
          onMouseEnter={() => setGhostProfileID(data.value)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
            height: isUpdatedRecently ? '2.5rem' : '1.3rem',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <div
            className="hide-on-selected"
            style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '0.1rem' }}
          >
            <p
              className="twenty-rem-width"
              style={{ textOverflow: 'ellipsis', maxWidth: '18rem', textWrap: 'nowrap', overflow: 'hidden' }}
            >
              {data.label}
            </p>
            {isUpdatedRecently && <i>last updated: {getLastUpdatedText(profile)}</i>}
          </div>

          <i
            title={getTitleByEmail(data.value)}
            style={{ float: 'right', marginLeft: 'auto', alignSelf: 'center' }}
            className="hide-on-selected"
          >
            {profilesMap.get(data.value)?.sortScore}
          </i>
        </div>
      );
    },
    [getLastUpdatedText, getTitleByEmail, profilesMap, setGhostProfileID]
  );

  const SelectedProfileDetails = ({ selectedProfile }: { selectedProfile: Profile | null }) => {
    return selectedProfile ? (
      <>
        <div className="main-button disabled no-background">
          <div className="verified-line">
            <p>verified as:</p>
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedProfile.email}</p>
          </div>
          <div className="verified-line">
            <p>last updated:</p>
            <span>
              <p>{getLastUpdatedText(selectedProfile)}</p>
              <p
                className="edit-profile-emoji"
                title="Edit Profile"
                onClick={() => window.open(edit_badges_url, '_blank')}
              >
                🛠️
              </p>
            </span>
          </div>
        </div>
        <button className={`main-button ${mode === 'collected' && 'active'}`} onClick={() => setMode('collected')}>
          <p>
            Badges Collected{' '}
            <i>
              ({selectedProfile.badgesIDs.length}/{badgesMap.size})
            </i>
          </p>
        </button>
        <button
          className={`main-button ${badgeSubmissions.length === 0 && 'disabled'}  ${mode === 'submitted' && 'active'}`}
          onClick={() => badgeSubmissions.length > 0 && setMode('submitted')}
        >
          {BadgesSubmittedComponent}
        </button>
        <button className={`main-button ${mode === 'journeys' && 'active'}`} onClick={() => setMode('journeys')}>
          <p>
            Journeys{' '}
            <i>
              ({numJourneysCompleted}/{exampleJourneys.length})
            </i>
          </p>
        </button>
        {/* <div className="verified-line" style={{ position: 'absolute', right: 0 }}>
          <p />
          <p
            onClick={() => {
              setSelectedProfileID(null);
            }}
            style={{ cursor: 'pointer' }}
          >
            back to home page
          </p>
        </div> */}
      </>
    ) : (
      <>
        {/* <button className="main-button disabled">
          <p>What is Talos Badges</p>
        </button> */}
        <button
          className={`main-button ${viewBadgeGallery && 'active'}`}
          onClick={() => setViewBadgeGallery(!viewBadgeGallery)}
        >
          <p>
            Badge Gallery <i>({badgesMap.size})</i>
          </p>
        </button>
        <button className="main-button" onClick={() => window.open(edit_badges_url, '_blank')}>
          Create / Edit Profile
        </button>
        <button className="main-button" onClick={() => window.open(badge_submission_url, '_blank')}>
          New Badge Submission
        </button>
      </>
    );
  };

  const effectiveBadge = sortedBadgesArrayForProfile.at(0) ?? null;
  const [loadedCount, setLoadedCount] = useState(0);
  const [displaySource, setDisplaySource] = useState<string>();

  useEffect(() => {
    setDisplaySource(effectiveBadge?.smallImageUrl);
    setLoadedCount(0);
  }, [effectiveBadge]);

  return (
    <div
      className={`container ${isDefault && (!isConsideredMobile ? 'default' : 'default-mobile')} fadeIn`}
      style={{ '--delay': '1500ms' } as React.CSSProperties}
    >
      <div className={`horizontal-flex ${isConsideredMobile && 'mobile-container'}`}>
        <div className="talos-badges-container">
          <img
            onLoad={() => {
              setImagesLoaded(true);
              if (loadedCount === 0) {
                setDisplaySource(effectiveBadge?.imageUrl);
              }
              setLoadedCount((prev) => prev + 1);
            }}
            src={displaySource ?? talosBadgesLogoURL}
            alt="Talos Logo"
            className={`talos-badges-logo glow-effect-${sortedBadgesArrayForProfile.at(0)?.rarityName}`}
          />
          {effectiveBadge && loadedCount < 2 && (
            <div style={{ position: 'relative' }}>
              <i className="loading-full-size-container">Loading full-size resource...</i>
            </div>
          )}
        </div>
        <div
          className={`main-buttons-container ${isConsideredMobile && 'main-buttons-container-mobile'}`}
          onMouseLeave={() => setGhostProfileID(selectedProfileID)}
        >
          <div className="h-stack" style={{ color: 'white' }}>
            <p>{selectedProfileName ? 'Talos Badges Profile' : 'Welcome to Talos Badges'}</p>
            <p
              title="Sort by"
              style={{
                cursor: 'pointer',
                userSelect: 'none',
                color: 'lightgray',
              }}
              onClick={() => {
                setSortBy(sortBy === 'score' ? 'last updated' : 'score');
              }}
            >
              ↓ {sortBy}
            </p>
          </div>
          <Select
            value={options.find((option) => option.value === selectedProfileID) ?? null}
            options={options}
            placeholder="Select profile..."
            isClearable
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            openMenuOnFocus={false}
            onChange={(option) => {
              const profileEmail = option?.value ?? null;
              setSelectedProfileID(profileEmail);
              setViewBadgeGallery(false);
            }}
            formatOptionLabel={handleFormatOptionLabel}
          />
          <SelectedProfileDetails selectedProfile={selectedProfile} />
        </div>
      </div>
      {selectedProfileID && mode !== 'journeys' && (
        <CollectedBadgesContainer
          badgesMap={badgesMap}
          badgeIDs={mode === 'collected' ? selectedProfile?.badgesIDs ?? [] : badgeSubmissions.map((badge) => badge.id)}
          onClickBadge={setSelectedBadge}
          selectedProfile={selectedProfile}
          viewNextBadges={selectedProfileID != null && mode === 'collected'}
          allowFilters={true}
          clearResultsOnFilter={true}
        />
      )}
      {viewBadgeGallery && !selectedProfileID && (
        <CollectedBadgesContainer
          badgesMap={badgesMap}
          badgeIDs={Array.from(badgesMap.keys())}
          onClickBadge={setSelectedBadge}
          selectedProfile={null}
          viewNextBadges={selectedProfileID != null}
          allowFilters={true}
          clearResultsOnFilter={true}
        />
      )}
      {mode === 'journeys' && selectedProfileID && (
        <JourneysContainer
          badgesMap={badgesMap}
          profilesMap={profilesMap}
          selectedProfile={selectedProfile}
          onClickBadge={setSelectedBadge}
        />
      )}
    </div>
  );
};
